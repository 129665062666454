<script setup lang="ts">
import { InviteMemberDocument } from './InviteModal.generated'

const open = defineModel<boolean>({ required: true })
const form = ref()
const container = ref<HTMLElement>()

const { height } = useElementSize(container)
const { y: containerYScroll } = useScroll(container, { behavior: 'smooth' })

const initialEmails = ['', '', '']
const state = reactive({ emails: [...initialEmails] })

function close() {
  open.value = false
  state.emails = [...initialEmails]
}

function addMore() {
  state.emails = [...state.emails, ...initialEmails]
  nextTick(() => containerYScroll.value = height.value)
}

const { execute: invite, isFetching: isInviting } = useMutation(InviteMemberDocument, { refetchTags: ['invites', 'flashMessages', 'users'] })

async function onSubmit() {
  const responses = await Promise.all(state.emails.filter(Boolean).map(email => invite({ input: { email, role: 'member' } })))

  responses.forEach((response, index) => {
    if (response.data?.inviteMember?.errors && response.data.inviteMember.errors.length > 0) {
      form.value.setErrors(
        Object.entries(response.data?.inviteMember?.errors).map(([_key, value]) => ({ path: `email[${index}]`, message: value?.messages.join(' ') })),
      )
    }
    else {
      state.emails[index] = ''
    }
  })

  if (responses.every(response => response.data?.inviteMember?.success ?? false))
    close()
}

function handlePaste(ev: ClipboardEvent) {
  if (!ev.clipboardData)
    return

  const pasted = ev.clipboardData.getData('text')
  const splitCharacter = ['\n', ',', ';', ' ', '	'].find(c => pasted.includes(c)) ?? '\n' // eslint-disable-line style/no-tabs

  const emails = pasted.split(splitCharacter).filter(Boolean)
  if (emails.length < 2)
    return

  ev.preventDefault()
  state.emails = [
    ...state.emails.filter(Boolean), // keep existing filled emails
    ...emails.map(e => e.trim()),
  ]
}
</script>

<template>
  <UModal v-model="open" fullscreen :ui="{ fullscreen: 'flex dark:!bg-gray-950/85 !bg-gray-950/70 items-center justify-center' }" @close="close">
    <div class="w-[40rem] max-w-full rounded-2xl border border-gray-300 bg-white p-4 dark:border-gray-700 dark:bg-gray-900">
      <div class="flex items-start justify-between">
        <div>
          <h3>Invite members to your organization</h3>
          <div class="text-base text-gray-700 dark:text-gray-200">
            Invite new people to join your organization.
          </div>
        </div>
        <UButton icon="light:xmark" variant="ghost" size="xl" color="gray" @click="close" />
      </div>

      <UForm ref="form" class="mt-4 flex flex-col" :state="state" :validate-on="['submit']" @submit="onSubmit">
        <div ref="container" class="max-h-96 overflow-auto p-0.5">
          <FormGroup
            v-for="(_email, index) in state.emails"
            :key="index"
            :name="`email[${index}]`"
          >
            <UInput
              v-model="state.emails[index]"
              class="mt-2"
              placeholder="name@example.com"
              icon="light:envelope"
              type="email"
              :disabled="isInviting || state.emails[index - 1]?.length === 0"
              :autofocus="index === 0"
              @paste="handlePaste"
            />
          </FormGroup>
        </div>
        <div class="my-4 flex items-center gap-x-1 text-xs text-gray-300">
          <UTooltip text="Can be split by newline, whitespace or comma">
            <UIcon name="light:circle-info" />
          </UTooltip>
          <span>You can paste multiple emails at once</span>
        </div>
        <div class="flex justify-between">
          <UButton color="gray" icon="light:plus" :disabled="state.emails.some(e => e.length === 0)" @click="addMore">
            Add more
          </UButton>
          <UButton type="submit" data-action="invite-to-org-button" :loading="isInviting">
            Invite to Organization
          </UButton>
        </div>
      </UForm>
    </div>
  </UModal>
</template>
